import '../styles/register.css'

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import NewsletterForm from './NewsletterForm';
import React from 'react';
import Timer from "./Timer";

const Register = () => {
  const { t } = useTranslation();
  const startDate = new Date("Jan 01, 2025 00:00:00").getTime();

  return (
    <>
      <div className="container register-ctn">
        <div className="register-newsletter">
          <h3>{t`register.page-subtitle`}</h3>
          <h1>
            {t`register.page-title-1a`} <br />
            {t`register.page-title-1b`}<br />
          </h1>
          <p id="subscribe-text">{t`register.first-step-text-1`}</p>
          <div className="home-content__subscribe">
            <NewsletterForm />
          </div>
          <p id="extra-info">
            {t`register.first-step-text-2a`}
            <Link to={'/program-inkubacyjny'}>{t`register.first-step-text-2b`}</Link>
            {t`register.first-step-text-2c`}
          </p>
        </div>
        <div className="register-counter home-content__counter">
          <h3>{t`register.timer-title`}</h3>
          <Timer startDate={startDate} />
        </div>
      </div>
    </>
  );
}

export default Register
