import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";

const BREVO_API_KEY = process.env.GATSBY_BREVO_API_KEY;

const NewsletterForm = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleFormSubmit = async () => {
    setError(null);
    setStatus('sending');

    if (!email) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      const response = await fetch('https://api.brevo.com/v3/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': BREVO_API_KEY
        },
        body: JSON.stringify({
          email: email,
          listIds: [28]
        })
      });
      const result = await response.json();
      if (response.ok) {
        setStatus('success');
      } else {
        setStatus('error');
        setError(result.message);
      }
    } catch (error) {
      setStatus('error');
      setError('An error occurred while submitting the form');
    }
  };


  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    if (event.keyCode === 13) {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  return (
    <div>
      <div id="mc-form">
        <input
          onChange={(event) => setEmail(event?.target?.value ?? '')}
          type="email"
          maxLength={80}
          placeholder={t`register.newsletter-email-address`}
          className="mc-email"
          onKeyUp={(event) => handleInputKeyEvent(event)}
        />
      </div>
      <button id="mc-form-submit" onClick={handleFormSubmit}>
        {t`register.newsletter-notify-me`}
      </button>
      <div id="mc-form-label">
        {error ? <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }}><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem" /></span>{t(error.replace(',', '').split(' ').join('-').toLowerCase())}</div> : null}
        {'sending' === status ? t(`register.newsletter-verification`) : null}
        {'success' === status && 'error' !== status && !error && (
          <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }}><FontAwesomeIcon icon={faCheck} width="1rem" height="1rem" /></span>{t`register.newsletter-success`}</div>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;