import './styles/style.scss';
import './styles/base.scss';

import * as React from 'react';

import FadeIn from 'react-fade-in';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../../components/layout/components/footer';
import Jurney from './components/jurney';
import ParticleBackground from './components/ParticleBackground';
import Register from './components/register';
import Seo from '../../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
// import RegisterOpen from './components/RegisterOpen';
import { ThemeProvider } from 'styled-components';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import ogImage from '../../images/seo/SEO_platforma.jpg';
import { theme } from '../../themes/default';
import { useTranslation } from 'gatsby-plugin-react-i18next';

function RegistrationPage() {
  const { t } = useTranslation();

  const [isInfo, setIsInfo] = React.useState(false);
  return (
    <ThemeProvider theme={theme}>
      <div className="registration-global">
        <Seo
          title={t`register.page-title`}
          description={t`register.page-description`}
          ogImage={ogImage}
        />
        <main className="s-home s-home--particles">
          <div id="particles-js" className="home-particles">
            <ParticleBackground />
          </div>
          <FadeIn delay={250} duration={450}>
            <div className="home-content">
              <div className="home-logo">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/rejestracja/platform.png"
                  alt="Innovations Platform logo"
                />
              </div>
              {/* <RegisterOpen />*/}
              <Register />
              <Jurney />
              <Footer darkTheme={true} />
            </div>
          </FadeIn>
        </main>
        <a
          className="info-toggle"
          onClick={() => {
            document.body.classList.toggle('info-is-visible');
            setIsInfo(!isInfo);
          }}>
          <span
            className="info-toggle-text"
            style={isInfo === false ? { opacity: 1 } : { opacity: 0 }}>
            {t`register.read-more`}
          </span>
          <span
            className="info-toggle-text"
            style={isInfo === true ? { opacity: 1 } : { opacity: 0 }}>
            {t`register.read-more-back`}
          </span>
          <span className="info-menu-icon" />
        </a>
        <div className="s-info">
          <div className="row info-wrapper">
            <div className="col-seven tab-full info-main">
              <h1>{t`register.second-page-title`}</h1>
              <p>{t`register.second-page-text-1`}</p>
              <p>{t`register.second-page-text-2`}</p>
              <StaticImage
                src="../../images/rejestracja/visual2.png"
                alt="Wizualizacja Innovations Platform"
              />
            </div>
            <div className="col-four tab-full pull-right info-contact">
              <div className="info-block">
                <h3>{t`register.second-page-contact`}</h3>
                <p>
                  <a
                    href="mailto:hello@innovationsplatform.pl"
                    className="info-email">
                    hello@innovationsplatform.pl
                  </a>
                  <br />
                  <a href="tel:+48 668 574 097" className="'info-phone">
                    +48 668 574 097
                  </a>
                </p>
              </div>

              <div className="info-block">
                <h3>{t`register.second-page-visit-us`}</h3>

                <p className="info-address">
                  {t`register.second-page-visit-us-address`}
                  <br />
                  {t`register.second-page-visit-us-zip`}
                  <br />
                  {t`register.second-page-visit-us-country`}
                </p>
              </div>
              <div className="info-block">
                <h3>{t`register.second-page-find-us`}</h3>
                <ul className="info-social">
                  <li>
                    <a href="https://www.facebook.com/InnovationsHubFoundation/">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        width="1rem"
                        height="1rem"
                      />
                      <span style={{ marginLeft: '1rem' }}>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/innovations-hub">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        width="1rem"
                        height="1rem"
                      />
                      <span style={{ marginLeft: '1rem' }}>LinkedIn</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/medbizinnovationsprogram/">
                      <FontAwesomeIcon
                        icon={faInstagram}
                        width="1rem"
                        height="1rem"
                      />
                      <span style={{ marginLeft: '1rem' }}>Instagram</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Footer darkTheme={true} />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default RegistrationPage;
